import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  Button,
  Grid,
  Box,
  CircularProgress,
  Card,
} from '@mui/material';
import ReactAudioPlayer from 'react-audio-player';
import Typography from '@mui/material/Typography';
import Dropzone from 'react-dropzone';
import {
  validateSelectedAudioFile,
  validateSelectedPdfFile,
  validateSelectedDocxFile,
} from '../../utils/file-validations.util';
import {
  deleteAssetFile,
  uploadAssetFile,
  viewVideoAssetById,
} from '../../services/content.service';
import ListData from '../table-elements/list-data.component';
import ConfirmPopup from '../common/confirm-popup.component';
import {
  LISTING_ACTIONS,
  LISTING_COLUMNS,
} from '../../config/module-configs/asset.config';
import { ACTIONS, ASSET_TYPE } from '../../config/const.config';

const AudioPdfUpload = ({
  dataId,
  dataTitle,
  showToastMsg,
  setSnackbarInfo,
  assetType,
  videoAssetsData,
  showUpdatedImage,
  onSuccess,
  bulkUpload = false,
}) => {
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    q: null,
    error: false,
    loading: true,
    searchValue: '',
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFilesArr, setSelectedFilesArr] = useState([]);
  const [showUploading, setShowUploading] = useState(false);
  const [hasFileTypeError, setHasFileTypeError] = useState(false);
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fileType = () => {
    let type = 'PDF';
    if (assetType === ASSET_TYPE.PODCAST) {
      type = 'Audio';
    } else if (assetType === ASSET_TYPE.TRANSCRIPT_DOCX) {
      type = 'Docx';
    }

    return type;
  };

  const validateSelectedFile = (selectedFiles) => {
    let error = false;

    if (assetType === ASSET_TYPE.PODCAST) {
      error = validateSelectedAudioFile(selectedFiles, bulkUpload);
    } else if (assetType === ASSET_TYPE.TRANSCRIPT_DOCX) {
      error = validateSelectedDocxFile(selectedFiles, bulkUpload);
    } else {
      error = validateSelectedPdfFile(selectedFiles, bulkUpload);
    }

    return error;
  };

  const handleSelectFiles = async (files) => {
    setHasFileTypeError(false);
    showUpdatedImage(false);
    const selectedFiles = files;

    setSelectedFilesArr(selectedFiles);
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedFile(selectedFiles);
    if (validationError) {
      setHasFileTypeError(true);
      showToastMsg('error', validationError);
    }

    setSelectedFileName(selectedFiles.map((key) => key.name).join(', '));
  };

  const handleUploadFiles = async (videoId) => {
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedFile(selectedFilesArr);
    if (validationError) {
      showToastMsg('error', validationError);
    } else {
      setShowUploading(true);

      try {
        const files = selectedFilesArr;

        const audioPdfFormData = new FormData();
        for (let loop = 0; loop < files.length; loop += 1) {
          audioPdfFormData.append('files[]', files[loop]);
          audioPdfFormData.append(
            'filesArr[]',
            JSON.stringify({
              fileName: files[loop].name,
              language: bulkUpload
                ? files[loop].name.split('-')[0].toUpperCase()
                : 'EN',
            })
          );
        }

        audioPdfFormData.append('videoId', Number(videoId));
        audioPdfFormData.append('assetType', assetType);

        const fileUploadHeader = await uploadAssetFile(
          audioPdfFormData,
          bulkUpload
        );

        if (Number(fileUploadHeader.data.filesUploaded) > 0) {
          showUpdatedImage(true);
          setSelectedFilesArr([]);
          setSelectedFileName('');
          // const assetName = CONCAT(UPPER(SUBSTRING('${assetType, 1, 1)), LOWER(SUBSTRING('${assetType}', 2)));
          onSuccess(
            `${fileUploadHeader.data.filesUploaded} ${dataTitle} file${
              fileUploadHeader.data.filesUploaded > 1 ? 's' : ''
            } uploaded sucessfully`
          );
          setOptions({
            ...options,
            reloadCounter: options.reloadCounter + 1,
          });
          // showToastMsg('success', 'file uploaded sucessfully');
        } else {
          showToastMsg('error', 'Something went wrong! Please try again.');
        }
      } catch {
        showToastMsg('error', 'Something went wrong! Please try again.');
      }
      setShowUploading(false);
    }
  };

  const handleFileNewTab = (fileUrl) => {
    window.open(`${fileUrl}?view=1`, '_blank');
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    showUpdatedImage(false);
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleDeleteFiles = async () => {
    setSnackbarInfo({
      show: false,
    });
    const payload = {
      videoId: dataId,
      assetType,
      language: doAction.data.label,
    };

    try {
      const cdnResponse = await deleteAssetFile(payload);

      if (cdnResponse.data?.success) {
        showUpdatedImage(true);
        setSelectedFilesArr([]);
        setSelectedFileName('');
        showToastMsg('success', `File Deleted Successfully!`);
        setOptions({
          ...options,
          reloadCounter: options.reloadCounter + 1,
        });
        handleActionCancel();
      } else {
        handleActionCancel();
        showToastMsg('error', 'Something went wrong! Please try again.');
      }
    } catch {
      showToastMsg('error', 'Something went wrong! Please try again.');
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const LinearProgressWithLabel = (props) => {
    const { value } = props;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  useEffect(() => {
    if (bulkUpload) {
      viewVideoAssetById(dataId, assetType)
        .then((res) => {
          setOptions({
            ...options,
            loading: false,
            totalRows: res?.data?.totalRows || 0,
            rows: res?.data?.rows || [],
            error: false,
          });
        })
        .catch((err) => {
          setOptions({
            ...options,
            loading: false,
            page: 0,
            totalRows: 0,
            rows: [],
            error: true,
          });

          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';

          setSnackbarInfo({
            show: true,
            type: 'error',
            message,
          });
        });
    }
  }, [dataId, options.reloadCounter]);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        display="flex"
        xs={12}
        sm={bulkUpload ? 9 : 12}
        md={bulkUpload ? 9 : 12}
        direction={bulkUpload ? 'column' : 'row'}
        margin="auto"
      >
        {!bulkUpload && (
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              whiteSpace: 'unset',
              wordBreak: 'break-all',
            }}
            direction={bulkUpload ? 'column' : 'row'}
          >
            <Grid item sx={{ fontWeight: 'bold' }}>
              {dataTitle}{' '}
              {assetType === ASSET_TYPE.TRANSCRIPT_DOCX ? '' : 'Preview:'}
            </Grid>

            {assetType === ASSET_TYPE.PODCAST &&
              videoAssetsData?.podcastUrl && (
                <>
                  <Grid item xs={12} sm={12} md={12}>
                    <ReactAudioPlayer
                      src={videoAssetsData?.podcastUrl}
                      controls
                    />
                  </Grid>
                  <Grid item sx={{ position: 'relative', top: '25px' }}>
                    <Box sx={{ fontWeight: 'bold' }}>Podcast Url:&nbsp;</Box>
                    <Box height="100px">
                      {videoAssetsData?.podcastUrl}
                      {'  '}
                    </Box>
                  </Grid>
                </>
              )}
            {assetType === ASSET_TYPE.TRANSCRIPT_PDF &&
              videoAssetsData?.transcriptUrl && (
                <Grid item xs={12} sm={12} md={12}>
                  <div>
                    <iframe
                      id="frame1"
                      title="frame1"
                      src={`${videoAssetsData?.transcriptUrl}?view=1`}
                      width="px"
                      height="255px"
                    />
                  </div>
                </Grid>
              )}
            {assetType === ASSET_TYPE.SLIDES && videoAssetsData?.slidesUrl && (
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <iframe
                    id="frame1"
                    title="frame1"
                    src={`${videoAssetsData?.slidesUrl}?view=1`}
                    width="px"
                    height="255px"
                  />
                </div>
              </Grid>
            )}
          </Grid>
        )}

        <Grid item xs={12} sm={8} md={8}>
          <Grid item xs={12} sm={12} md={12}>
            <Dropzone onDrop={(files) => handleSelectFiles(files)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />

                    <Box
                      component="section"
                      sx={{
                        p: 3,
                        border: '1px dashed grey',
                        borderRadius: '20px',
                        width: '100%',
                        marginTop: 3,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          Add/Replace {dataTitle} file
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              textAlign: 'center',
                            }}
                          >
                            Drag and drop {fileType()} files here, or click to
                            browse
                          </Grid>
                          <DialogActions sx={{ justifyContent: 'center' }}>
                            {!showUploading && (
                              <Button
                                component="label"
                                variant="contained"
                                sx={{
                                  backgroundColor: '#808080',
                                }}
                              >
                                Select {fileType()} File
                              </Button>
                            )}

                            {showUploading && (
                              <Box
                                display="flex"
                                alignItems="center"
                                padding="6px 16px"
                                borderRadius="6px"
                                sx={{
                                  backgroundColor: '#919eab3d',
                                  color: '#919eabcc',
                                }}
                              >
                                <CircularProgress
                                  disableShrink
                                  size="18px"
                                  sx={{ marginRight: 1, color: '#919eabcc' }}
                                />
                                <span>Uploading...</span>
                              </Box>
                            )}
                          </DialogActions>

                          {bulkUpload && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                              }}
                            >
                              Please upload {fileType()} files with language
                              specific format:
                              <br />
                              &#60;ISO 639 language code&#62;-&#60;media
                              type&#62;-&#60;book name&#62;-&#60;lesson #&#62;
                              <br />
                              &#40;example: en-
                              {fileType() === 'Audio' ? 'audio' : 'text'}
                              -genesis-l01
                              {fileType() === 'Audio'
                                ? '.mp3'
                                : `.${fileType().toLowerCase()}`}
                              &#41;
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {`${selectedFileName}`}
                      </Grid>
                    </Box>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>

          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              disabled={showUploading || hasFileTypeError}
              onClick={() => handleUploadFiles(dataId, dataTitle)}
            >
              Upload {dataTitle}{' '}
            </Button>
          </DialogActions>

          {assetType === ASSET_TYPE.SLIDES && videoAssetsData?.slidesUrl && (
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 2,
              }}
            >
              <Box sx={{ fontWeight: 'bold' }}>{dataTitle} URL:&nbsp;</Box>
              <Box sx={{ fontSize: '15px' }}>{videoAssetsData?.slidesUrl}</Box>
            </Grid>
          )}
        </Grid>
      </Grid>

      {bulkUpload && (
        <Grid item xs={12} sm={12} md={12}>
          <Card>
            <Grid item sx={{ marginTop: 2 }}>
              List of {dataTitle} files:
            </Grid>

            <ListData
              columns={columns}
              rows={options.rows}
              page={options.page}
              rowsPerPage={options.totalRows}
              totalRows={options.totalRows}
              loading={options.loading}
              actions={actions}
              error={options.error}
              sortBy={options.sortBy}
              sortOrder={options.sortOrder}
              onPageChange={() => {}}
              onRowsPerPageChange={() => {}}
              onSortChange={() => {}}
              onAction={handleActionSelect}
              onCustomClick={handleFileNewTab}
            />
          </Card>

          {doAction.action === ACTIONS.DELETE.value && (
            <ConfirmPopup
              title={`Delete ${assetType} File for respective language`}
              message={`Do you want to delete ${fileType()} file? You can not undo this action!`}
              onCancel={handleActionCancel}
              onSuccess={handleDeleteFiles}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

AudioPdfUpload.propTypes = {
  dataId: PropTypes.number.isRequired,
  dataTitle: PropTypes.string.isRequired,
  showToastMsg: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  videoData: PropTypes.object.isRequired,
  videoAssetsData: PropTypes.object.isRequired,
  setSnackbarInfo: PropTypes.object.isRequired,
  videoContentType: PropTypes.string.isRequired,
  assetType: PropTypes.string.isRequired,
  showUpdatedImage: PropTypes.bool.isRequired,
  bulkUpload: PropTypes.bool.isRequired,
};

export default AudioPdfUpload;
